
























import Vue from "vue";
import { Component } from "vue-property-decorator";

import { axiosInstance as axios } from "@/store/";

@Component
export default class FakePartnerAuth extends Vue {
  uuid = "";

  async auth(): Promise<void> {
    if (!this.uuid) return;

    try {
      const response = await axios.post(
        process.env.VUE_APP_FAKE_AUTH_PATH,
        {
          UUID: this.uuid,
          OneTimeUse: false,
        },
        {
          headers: {
            "x-cg-api-key": process.env.VUE_APP_POPUP_FAKE_APIKEY,
          },
        }
      );

      if (window.opener) {
        window.opener.postMessage(
          {
            cgtoken: response.data.token,
          },
          location.origin
        );
      }

      // wait for 1 second to make sure the message is sent
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Redirect anyway even if the postMessage is not working

      let url = new URL(location.origin);
      // get referrer
      if (document.referrer) url = new URL(document.referrer);

      const token = response.data.token;
      url.searchParams.set("cgtoken", token);

      location.replace(url.href);
    } catch (err) {
      window.opener.postMessage(
        {
          error: "auth_fail",
        },
        location.origin
      );
    }
  }

  fail(): void {
    window.opener.postMessage(
      {
        error: "auth_fail",
      },
      location.origin
    );
  }
}
